import * as React from "react"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"

import OrderListItem from "./orderListItem"

import NoFoodOutlinedIcon from "@mui/icons-material/NoFoodOutlined"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(10, 1fr)",
    [theme.breakpoints.down("lg")]: {
      gridTemplateColumns: "repeat(12, 1fr)",
    },
  },

  orderListBox: {
    width: "100%",
    paddingTop: "0.75rem",
    paddingLeft: "0.75rem",
  },
  orderListGrid: { width: "100%" },

  outBox: {
    width: "100%",
    minHeight: "calc(100svh  - 240px)",
    position: "relative",
  },
  inBox: {
    width: "80%",
    textAlign: "center",
    position: "absolute",
    left: "50%",
    top: "55%",
    msTransform: "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
  },

  icon: {
    color: theme.palette.common.blackNine,
    fontSize: "6rem !important",
  },
  noOrderText: {
    marginTop: "0.5rem",
    color: theme.palette.common.blackFourteen,
  },
}))

const MainOrderList = ({
  ordersIn,
  user,
  dispatchFeedback,
  setAlarm,
  setOrdersIn,
  kitchenSettings,
  section,
  printer,
  setCurrentOrdCount,
  selectedOrderStateOption,
  selectedDetailLvl,
  setPrintAlert,
}) => {
  const classes = useStyles()
  const [isAllLoading, setIsAllLoading] = React.useState(false)
  let count = 0

  return (
    <Box classes={{ root: classes.orderListBox }}>
      <div className={classes.container}>
        {ordersIn.map((orderItem, index) => {
          if (selectedOrderStateOption.value === orderItem.filterOpt) {
            count++
            return (
              <OrderListItem
                isAllLoading={isAllLoading}
                setIsAllLoading={setIsAllLoading}
                orderItem={orderItem}
                ordersIn={ordersIn}
                user={user}
                dispatchFeedback={dispatchFeedback}
                setAlarm={setAlarm}
                setOrdersIn={setOrdersIn}
                kitchenSettings={kitchenSettings}
                key={`${orderItem.id}-${orderItem.orderVersion}-${index}`}
                section={section}
                printer={printer}
                setCurrentOrdCount={setCurrentOrdCount}
                selectedOrderStateOption={selectedOrderStateOption}
                selectedDetailLvl={selectedDetailLvl}
                setPrintAlert={setPrintAlert}
              />
            )
          } else {
            return null
          }
        })}
      </div>
      {count === 0 ? (
        <Box classes={{ root: classes.outBox }}>
          <Box classes={{ root: classes.inBox }}>
            <NoFoodOutlinedIcon
              classes={{ root: classes.icon }}
            ></NoFoodOutlinedIcon>
            <Typography
              variant="h4"
              classes={{ root: classes.noOrderText }}
            >{`No ${selectedOrderStateOption.value} Orders Yet!`}</Typography>
          </Box>
        </Box>
      ) : (
        <></>
      )}
    </Box>
  )
}

export default MainOrderList
